import { apiRequest } from "utils/async/apiUtils";
import { message } from "antd";

export const loadProfileDetails = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("get", "profile");

    const data = res.data;
    if (data.status === 200) setResData(data.data);
    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
    return err;
  }
};

export const editProfile = async (data, setLoaded = () => {}) => {
  setLoaded(false);
  try {
    const res = await apiRequest(
      "put",
      "/profile/update",
      {
        body: {
          ...data,
        },
      },
      "multipart/form-data"
    );
    setLoaded(true);
    message.success(res.data.message);
    return res;
  } catch (error) {
    setLoaded(true);
    return error;
  }
};

export const loadCurrencyList = async (
  setResData = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("get", "currency");

    const data = res.data;
    if (data.status === 200) setResData(data.docs);
    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};

export const loadTimezoneData = async (
  params,
  setResData = () => {},
  setLoaded = () => {}
) => {
  setLoaded(false);
  try {
    const res = await apiRequest("get", "timezones", {
      queries: {
        ...params,
      },
    });

    const data = res.data;
    if (data.status === 200) setResData(data.data);
    setLoaded(true);
    return res;
  } catch (err) {
    setLoaded(true);
    return err.response;
  }
};
