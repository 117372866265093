import { createSlice } from "@reduxjs/toolkit";

const driverSlice = createSlice({
  name: "driver",
  initialState: {
    data: {},
    image: null,
  },
  reducers: {
    setDriverDetails: (state, action) => {
      state.data = action.payload.data;
      state.image = action.payload.image;
    },
    clearDriverDetails: (state) => {
      state.data = {};
      state.image = null;
    },
  },
});

export const { setDriverDetails, clearDriverDetails } = driverSlice.actions;
export default driverSlice.reducer;
