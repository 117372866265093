import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  walletData: {},
};

export const walletSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setWalletDetails: (state, action) => {
      // console.log(action.payload, "action.payloadaction.payload");
      state.loading = false;
      state.walletData = action.payload;
    },
  },
});

export const { setWalletDetails } = walletSlice.actions;

export default walletSlice.reducer;
