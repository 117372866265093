import { combineReducers } from "redux";
import theme from "./slices/themeSlice";
import auth from "./slices/authSlice";
import topic from "./slices/topicSlice";
import lang from "./slices/langSlice";
import profile from "./slices/profileSlice";
import settings from "./slices/settingsSlice";
import permission from "./slices/permissionSlice";
import loggedUser from "./slices/currentLoggedUserSlice";
import image from "./slices/imageSlice";
import wallet from "./slices/walletSlice";
import driver from "./slices/driverDetailsSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    lang,
    topic,
    profile,
    settings,
    loggedUser,
    permission,
    image,
    wallet,
    driver,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
