import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  AUTH_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
  REDIRECT_URL_KEY,
} from "configs/AppConfig";
import { verifyToken } from "services/appServices/auth";
import Loading from "components/shared-components/Loading";
import { getUserData } from "store/slices/currentLoggedUserSlice";
import { apiRequest } from "utils/async/apiUtils";

const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState({
    loaded: false,
    verified: false,
  });
  const token = localStorage.getItem("auth_token");
  const location = useLocation();

  useEffect(() => {
    verifyToken(setAuthenticated).then((res) => {
      if (res?.status === 200) {
        dispatch(getUserData(res?.message));
      }
    });
   
  }, []);

  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`}
        replace
      />
    );
  }

  if (!authenticated.loaded) {
    return <Loading />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
